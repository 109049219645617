<script setup lang="ts">
import { toDetail } from './healpers'
import BlogCoverPh from '~/assets/imgs/blog-cover-fallback.png'

const props = defineProps<{
  id: number
  title: string
  alias: string
  cover: string
  summary: string
  time: number
  category: string
}>()
const detailURL = computed(() => decodeURIComponent(localePath(toDetail(props.category, props.alias || props.title, props.id))))
</script>

<template>
  <NuxtLink class="group/item grid content-start items-start gap-x-30px gap-y-20px grid-rows-[auto_1fr] grid-cols-[auto_1fr_auto] text-[#8098BF] lt-tabletl:gap-x-15px lt-tabletl:gap-y-5px lt-tabletl:grid-cols-[auto_1fr] new-item" :to="detailURL">
    <!-- 封面 -->
    <div class="relative row-span-2 pb-50% w-300px lt-tablet:w-150px lt-tablet:rounded-10px">
      <NuxtImg class="absolute block w-full h-full object-cover rounded-20px lt-tablet:rounded-10px" :src="props.cover || BlogCoverPh" :placeholder="BlogCoverPh" :alt="props.title" />
    </div>

    <!-- 标题 -->
    <h3 class="self-center text-#E2E8F2 text-22px lh-normal break-all line-clamp-2 max-h-[calc(1em*1.5*2)] group-hover/item:text-#2A72E9 lt-tablet:text-16px">{{ props.title }}</h3>

    <!-- 发布时间 -->
    <time class="h-1.5em lh-normal lt-tabletl:hidden">{{ $d(props.time * 1000) }}</time>

    <!-- 简介 -->
    <p class="line-clamp-3 lh-normal text-18px break-all max-h-[calc(1em*1.5*3)] lt-tablet:line-clamp-2 lt-tablet:text-14px lt-tablet:max-h-[calc(1em*1.5*2)]">{{ props.summary }}</p>
  </NuxtLink>
</template>
