<script setup lang="ts">
const props = defineProps<{
  keyword?: string
}>()

const keyword = ref(props.keyword)
</script>

<template>
  <form class="search-input-wrap" :action="localePath('/blog/search')">
    <i class="i-ph:magnifying-glass-bold icon" />
    <input v-model="keyword" name="keyword" type="search" :placeholder="$t('9u9BgajXz6LdDNnf6oWn')" data-test-id="9p31">
    <div v-show="keyword" class="clear-btn" @click="keyword = ''" data-test-id="9p3p">
      <i class="i-material-symbols:cancel-rounded" />
    </div>

    <!-- 使nuxt能索引到这个路由 -->
    <NuxtLinkLocale v-show="false" to="/blog/search" data-test-id="mjvH" />
  </form>
</template>

<style scoped lang="scss">
.search-input-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 15px;
  background: #202C52;

  .icon {
    margin: 0 0.5em;
    color: #8098BF;
    font-size: 1.4em;
  }

  input {
    height: 100%;
    height: 40px;
    flex: 1;
    padding: 0;
    border: none;
    background: inherit;
    caret-color: #2A72E9;
    color: #fff;
    outline: none;

    &::placeholder {
      color: #8098BF;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
        -webkit-text-fill-color: #fff;
        transition: background-color 9999s ease-in-out 0s;
    }
  }

  .clear-btn {
    position: absolute;
    right: 0.5em;
    display: flex;
    width: 1.6em;
    height: 1.6em;
    align-items: center;
    justify-content: center;
    color: #8098BF;
    cursor: pointer;

    &:hover {
      color: #2B72E9;
    }
  }
}
</style>
