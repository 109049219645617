<script setup lang="ts">
import { toDetail } from './healpers'
import BlogCoverPh from '~/assets/imgs/blog-cover-fallback.png'

const props = defineProps<{
  id: number
  title: string
  alias: string
  cover: string
  summary: string
  time: number
  category: string
}>()
const detailURL = computed(() => decodeURIComponent(localePath(toDetail(props.category, props.alias || props.title, props.id))))
</script>

<template>
  <NuxtLink class="block overflow-hidden rounded-4 bg-#202C52 hover:shadow-[0_0_0_3px_#2B72E9] new-item" :to="detailURL">
    <div class="relative pb-50%">
      <NuxtImg class="absolute block w-full h-full object-cover cover" :src="props.cover || BlogCoverPh" :placeholder="BlogCoverPh" :alt="props.title" />
    </div>

    <div class="flex flex-col items-start h-140px m-20px lt-tablet:m-15px lt-tablet:h-auto">
      <h3 class="text-#E2E8F2 text-lg font-600 lh-normal break-all line-clamp-2 max-h-[calc(1em*1.5*2)]">{{ props.title }}</h3>
      <p class="my-10px line-clamp-2 lh-normal text-base break-all max-h-[calc(1em*1.5*2)] text-#8098BF">{{ props.summary }}</p>
      <time class="mt-auto text-#8098BF" pubdate>{{ $d(props.time * 1000) }}</time>
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.aaaa {
  color: wheat;
}
</style>
