<script setup lang="ts">
import { toDetail } from './healpers'
import BlogCoverPh from '~/assets/imgs/blog-cover-fallback.png'

const props = defineProps<{
  id: number
  title: string
  alias: string
  cover: string
  category: string
}>()
const detailURL = computed(() => decodeURIComponent(localePath(toDetail(props.category, props.alias || props.title, props.id))))
</script>

<template>
  <NuxtLink class="group/item text-#8098BF new-item" :to="detailURL">
    <!-- 封面 -->
    <div class="relative pb-50%">
      <NuxtImg class="absolute block w-full h-full rounded-15px bg-#222D50 object-cover cover" :src="props.cover || BlogCoverPh" :placeholder="BlogCoverPh" :alt="props.title" />
    </div>

    <!-- 标题 -->
    <h3 class="mt-10px text-#b1bdd1 text-4 lh-normal break-all line-clamp-2 max-h-[calc(1em*1.5*2)] group-hover/item:text-#2A72E9">{{ props.title }}</h3>
  </NuxtLink>
</template>

